import "./App.css";

function App() {
  return (
    <>
      <div className="comming-soon">
        <h1 className="company-name">MDP Associates</h1>
        <h1 className="coming-soon-txt">Coming Soon</h1>
      </div>
    </>
  );
}

export default App;
